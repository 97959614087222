<template>
  <div>
    <container>
      <div slot="left_box_content">
        <component :is="curLeftComponent" ></component>
      </div>
      <div slot="right_box_content">
        <component :is="curRightComponent" ></component>
      </div>
      <div
        slot="bottom_box_content"
        style="padding:10px 10px;"
      >
        <component
          ref="bottom"
          :is="curBottomComponent"
        ></component>
      </div>
      <div slot="bg_map"></div>
    </container>
  </div>
</template>
<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
import tenantStoreModule from '@/store/modules/tenant'
export default {
  mixins: [sysMixins],
  components: {
    Container,
    List: () => import('./components/List.vue'),
    Right: () => import('./components/Right.vue')
  },
  created () {
    if (!this.$store.hasModule('tenant')) {
      this.$store.registerModule('tenant', tenantStoreModule)
    }
  },
  mounted () {
    this.setShowLeftBlock(false)
    this.setShowRightBlock(true)
    this.setShowMap(false)
    this.setBottomHeight('90%')
    this.setBottomComponent('List')
    this.setRightComponent('Right')
  },
  destroyed () {
    this.$store.unregisterModule('tenant')
    this.setShowLeftBlock(false)
    this.setShowMap(false)
    this.setShowRightBlock(false)
    this.setBottomHeight('90%')
    this.setLinkTagArray()
    this.setLinkRouterMaps()
    this.setActivedTagName('')
  }
}
</script>
