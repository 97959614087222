import request from '@/utils/requestV2'
const qs = require('qs')

// 获取客户状态类型统计
export function getAdvertiserStatusStatistics (data) {
  return request({
    url: '/ooh-crm/v1/advertiserbrandstatistic/getadvertiserstatusstatistics',
    method: 'post',
    data: qs.stringify(data)
  })
}
