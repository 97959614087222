
import { getAdvertiserStatusStatistics } from '@/api/crm/advertiserbrandstatistic'
const getDefaultState = () => {
  return {
    tenantTypeStaData: [], // 租户类型统计数据
    tenantStatusStaData: [] // 租户状态统计数据
  }
}

const state = getDefaultState()

const tenant = {
  state,
  mutations: {
    set_type_sta_data (state, data) {
      state.tenantTypeStaData = data
    },
    set_status_sta_data (state, data) {
      state.tenantStatusStaData = data
    }
  },
  actions: {
    /**
     * 获取租户状态统计
     */
    getTenantStatusStaData ({ commit }, query) {
      return new Promise((resolve) => {
        getAdvertiserStatusStatistics(query).then(res => {
          switch (query.statistics) {
            case 1:
              commit('set_status_sta_data', res)
              break
            case 2:
              commit('set_type_sta_data', res)
              break
          }
          resolve(res)
        })
      })
    }
  }
}

export default tenant
